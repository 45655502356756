import snbIcon1 from '../assets/images/SNB_Icon_1.png';
import snbIcon2 from '../assets/images/SNB_Icon_2.png';
import snbIcon3 from '../assets/images/SNB_Icon_3.png';
import snbIcon4 from '../assets/images/SNB_Icon_4.png';
import snbIcon5 from '../assets/images/SNB_Icon_5.png';

// overview page
const tableData = [
    {
        asset: { firstValue: 'SNB', secondValue: '$14.08', icon: snbIcon1 },
        supplyTotalApy: { firstValue: '5.58%' },
        totalSupply: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
        borrowTotalApy: { firstValue: '-5.58%' },
        totalBorrow: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
        totalLiquidity: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
    },
    {
        asset: { firstValue: 'SNB', secondValue: '$14.08', icon: snbIcon2 },
        supplyTotalApy: { firstValue: '5.58%' },
        totalSupply: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
        borrowTotalApy: { firstValue: '-5.58%' },
        totalBorrow: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
        totalLiquidity: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
    },
    {
        asset: { firstValue: 'SNB', secondValue: '$14.08', icon: snbIcon3 },
        supplyTotalApy: { firstValue: '5.58%' },
        totalSupply: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
        borrowTotalApy: { firstValue: '-5.58%' },
        totalBorrow: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
        totalLiquidity: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
    },
    {
        asset: { firstValue: 'SNB', secondValue: '$14.08', icon: snbIcon4 },
        supplyTotalApy: { firstValue: '5.58%' },
        totalSupply: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
        borrowTotalApy: { firstValue: '-5.58%' },
        totalBorrow: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
        totalLiquidity: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
    },
    {
        asset: { firstValue: 'SNB', secondValue: '$14.08', icon: snbIcon5 },
        supplyTotalApy: { firstValue: '5.58%' },
        totalSupply: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
        borrowTotalApy: { firstValue: '-5.58%' },
        totalBorrow: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
        totalLiquidity: { firstValue: '1.10M SNG', secondValue: '$14.08M' },
    },
];

export { tableData };
