export const isMobile = () => {
  const mobiles = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return mobiles.some((matches) => {
    return navigator.userAgent.match(matches);
  });
};

export const PAGE_SIZE = 20;
export const MAX_INT =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

export const COOKIE_NAME = "partTracing";

export const BSC_CONFIG = {
  chainName: "BSC Testnet",
  chainId: "0x61",
  rpcUrls: [process.env.REACT_APP_BSC_RPC_URL],
};

export const API_URL = process.env.REACT_APP_API_URL;

export const UserRole = {
  USER: 1,
  ADMIN: 2,
};
// export const BSC_CONFIG = {
//   chainName: 'BSC Mainnet',
//   chainId: '0x38',
//   rpcUrls: ['https://bsc-dataseed.binance.org/'],
// };
