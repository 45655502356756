import { clientAccount } from './axios';

const nonce = (body) => clientAccount.post(`/authentication/nonce`, body);
const login = (body) => clientAccount.post(`/authentication/token`, body);
const getProfile = () => clientAccount.get(`/authentication/profile`);

const accountService = {
  nonce,
  login,
  getProfile,
};

export default accountService;
