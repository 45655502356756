import { notification } from "antd";
import { profileAction } from "actions/profile";
import { store } from "reducers";
import { stringify } from "query-string";
import axios from "axios";
import { API_URL } from "utils/constants";

const onError = ({ response }) => {
  if (response) {
    const { status, data, statusText } = response;
    if (status === 401) {
      profileAction.signOut();
    } else if (status < 500) {
      var message = `${status} - ${
        statusText || data.message || data?.errors?._?.[0] || "Error"
      }`;
      notification.error({ key: "axios", message });
    } else {
      notification.error({
        key: "axios",
        message: `${status} - ${statusText || data.message || "Error"}`,
      });
    }
  } else {
    notification.error({ key: "axios", message: "Cannot connect to Server" });
  }
  return Promise.reject(response);
};

const beforeRequest = (config) => {
  const { isLoggedIn, accessToken } = store.getState().profile;
  if (isLoggedIn) {
    Object.assign(config.headers, { Authorization: `Bearer ${accessToken}` });
  }
  if (config.data instanceof FormData) {
    Object.assign(config.headers, { "Content-Type": "multipart/form-data" });
  }
  return config;
};

const clientAccount = axios.create({
  baseURL: API_URL,
  paramsSerializer: (params) => stringify(params, { arrayFormat: "" }),
});
clientAccount.interceptors.request.use(beforeRequest);

[clientAccount].forEach((client) => {
  client.interceptors.response.use(({ data: response }) => {
    const { success, data, errors } = response;
    if (success) return data || response;
    else {
      var message = "BAD REQUEST";
      try {
        message = errors._[0];
        message = message.charAt(0).toUpperCase() + message.slice(1);
      } catch {}
      notification.error({ key: "axios", message });
      return Promise.reject({});
    }
  }, onError);
});

const client = axios.create({
  baseURL: API_URL,
  paramsSerializer: (params) => stringify(params, { arrayFormat: "" }),
});
client.interceptors.request.use(beforeRequest);

[client].forEach((client) => {
  client.interceptors.response.use(({ data: response }) => {
    const { success, data, message } = response;
    if (success) return data || response;
    else {
      notification.error({ key: "axios", message });
      return Promise.reject({});
    }
  }, onError);
});

export { clientAccount, client };
