import styled from 'styled-components';
import img from './assets/images/BG_Shape_1.png';

export const AppWrapper = styled.div`
    margin-left: 255px;
    min-height: 100vh;
    box-sizing: border-box;
    padding-top: 5rem;

    background: linear-gradient(180deg, #010000 0%, #360201 89.44%);
`;

export const AppImageBackgroundWrapper = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background: url(${img}) no-repeat bottom 0px right 0px fixed;
    background-size: contain;
`;
