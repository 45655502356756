import React from 'react';
import { InfoBoxWrapper } from './InfoBoxStyle';

function InfoBox(props) {
    const { title, content, boxStyle } = props;

    return (
        <InfoBoxWrapper>
            <h4>{title}</h4>
            <div className="asset-box">
                <div className="boxed-child" style={{ ...boxStyle }}>
                    {content}
                </div>
            </div>
        </InfoBoxWrapper>
    );
}

export default InfoBox;
