import styled from 'styled-components';

export const LandingPageWrapper = styled.div`
    box-sizing: border-box;
    margin: 0;
    background-image: linear-gradient(#010000, #360201);

    .Home {
        padding-top: 1rem;
        height: 900px;
        position: relative;
        width: 100%;
    }

    .App-header {
        height: 4.563rem;
        display: flex;
        justify-content: space-evenly;
        margin-left: -3.125rem;
        line-height: 4.563rem;
    }
    .App-logo {
        height: 2.313rem;
        margin-top: 0.5rem;
    }

    .App-menu {
        display: flex;
        align-items: center;
    }

    .App-btn {
        color: #fff;
    }

    .App-menu-list {
        list-style: none;
        display: flex;
        justify-content: space-between;
        width: 17.5rem;
        padding: 0;
    }
    .App-menu-list-item a {
        text-align: center;
        color: #ffffff;
        text-decoration: none;
        font-size: 0.938rem;
    }

    .Home-background {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
    }

    .Center-text {
        width: 46rem;
        height: 21.938rem;
        margin: auto;
        margin-top: 6.25rem;
    }
    .Center-text span {
        color: #ffffff;
    }
    .Center-text_Supply-Liquidity {
        font-size: 3.813rem;
        font-weight: 800;
        text-align: center;
        margin-bottom: 1rem;
    }
    .Center-text_money {
        font-size: 8.125rem;
        font-weight: 800;
        text-shadow: 0 0 2.875rem #ff6600;
    }
    .Center-text_Describe {
        font-size: 2.188rem;
        text-align: center;
        font-weight: 1;
    }
    .Center-text_Describe span {
        display: block;
    }
    .Power-text {
        width: 15.125rem;
        height: 2.25rem;
        margin: auto;
        text-align: center;
        margin-top: 9.375rem;
    }
    .Power-text span {
        color: #ffffff;
        font-size: 1.5rem;
        font-weight: 300;
    }
    .layer-img {
        margin: 0 0.313rem 0 1rem;
    }
    /* End page Home */

    ///////////////////////////////////////

    .Services {
        display: flex;
        width: 100%;
    }
    .Column-left {
        width: 50%;
        position: relative;
    }
    .Column-right {
        width: 50%;
        position: relative;
    }
    .Shape-left {
        position: absolute;
        top: 0;
        img {
            height: 600px;
        }
    }
    .Shape-right {
        float: right;
        top: 0;
        img {
            height: 600px;
        }
    }
    .Main {
        // width: 100%;
        height: 400px;
        border: 1px solid;
        padding: 55.5px;
    }
    .Main_left {
        border-image-slice: 1;
        border-image-source: linear-gradient(to bottom right, #ffee31, #af024a);
        border-left: none;
        background-color: #000000;
        padding-left: 240px;
    }
    .Main_right {
        border-image-slice: 1;
        border-image-source: linear-gradient(to bottom left, #ffee31, #af024a);
        border-right: none;
        background-color: #19000e;
        padding-right: 240px;
    }

    .Content {
        position: absolute;
        top: 55.5px;
        display: block;
        width: 483px;
        height: 469px;
        color: #ffffff;
    }

    .Content_title {
        font-size: 60px;
        display: flex;
        flex-direction: column;
    }

    .Content_description {
        font-size: 18px;
    }
    .Button-service {
        background-image: linear-gradient(to bottom right, #f63804, #ffee31);
        padding: 1px;
        border-radius: 8px;
        width: 174px;
        height: 57px;
        margin-top: 92px;
    }
    .Btn-app {
        width: 100%;
        height: 100%;
        background-color: #19000e;
        border-radius: inherit;
        text-align: center;
    }
    .Btn-app-children {
        font-weight: 100;
        font-size: 20px;
        line-height: 57px;

        background: -webkit-linear-gradient(#ffee31, #ff6600);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .BGr-0000 {
        background-color: #000000 !important;
    }

    ///////////////////////////////

    .LendingStats {
        width: 100%;
        height: 67.5rem;
    }

    .Title {
        width: 49.688rem;
        height: 6.563rem;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        margin: auto;
    }
    .Title > span:first-child {
        font-size: 3.125rem;
        text-align: center;
        text-shadow: 0 0 46px #ff6600;
    }
    .Title > span:last-child {
        font-size: 2.5rem;
    }
    .Assets {
        margin: 1rem auto 3.125rem auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        max-width: 1000px;
    }

    .Asset {
        width: 14.313rem;
        height: 8.438rem;
        color: #ffffff;
        background-color: #250000;
        padding: 1rem;
        margin-top: 15px;
    }
    .Assets-title {
        font-size: 1.276rem;
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
    }

    .Assets-stats {
        font-size: 0.962rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0.625rem;
        text-align: left;
        margin-top: 1.25rem;
    }
    .Assets-stats > div {
        display: flex;
        flex-direction: column;
    }
`;
