import React from 'react';
import { BannerWrapper } from './BannerStyle';
import InfoIcon from '../../assets/images/InfoIcon.png';
import { Slider } from '@material-ui/core';

function Banner(props) {
    const { title, value, percentValue } = props;
    const renderValue = (value) =>
        (!percentValue ? '$ ' : '') +
        Number(value).toLocaleString('en') +
        (percentValue ? ' %' : '');

    return (
        <BannerWrapper>
            <div className="title">
                {title}
                {percentValue && <img src={InfoIcon} />}
            </div>
            <div className="value">{renderValue(value)}</div>
            {percentValue && (
                <>
                    <Slider />
                    <div className="borrow-wrapper">
                        <div className="health-wrapper">
                            <span className="health-icon"></span>
                            <span>Health: 100</span>
                        </div>
                        <div className="remaining-wrapper">
                            <img src={InfoIcon} />
                            <span>Remaining: $0.00</span>
                        </div>
                    </div>
                </>
            )}
        </BannerWrapper>
    );
}

export default Banner;
