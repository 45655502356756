import styled from 'styled-components';

export const InfoBoxWrapper = styled.div`
    h4 {
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 8px;
    }
    .asset-box {
        display: flex;
        justify-content: center;
        background-image: linear-gradient(to bottom, #f63804, #ffee31);
        padding: 1px;
        border-radius: 0.375rem;

        .boxed-child {
            width: 100%;
            height: 100%;
            padding: 0.75rem;
            background-color: #250000;
            border-radius: inherit;
            text-align: center;
        }
    }
`;
