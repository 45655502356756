import styled from 'styled-components';

export const CardWrapper = styled.div`
    width: 70%;
    padding: 1.75rem;
    border-radius: 24px;
    background-color: #250000;
    z-index: 1;
`;

export const CardLayoutWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
