import React from "react";
import jwtDecode from "jwt-decode";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import Layout from "layout";
import { store } from "reducers";
import { profileAction } from "actions/profile";
import { QueryClientProvider, useQuery } from "react-query";
import { queryClient, systemService } from "services";
import cookie from "react-cookies";
import { COOKIE_NAME } from "utils/constants";

import "antd/dist/antd.less";
import "./styles/style.css";
import "App.scss";
import { AppImageBackgroundWrapper, AppWrapper } from "AppStyle";
import { systemAction } from "actions/system";
import Sidebar from "components/Sidebar";
import Markets from "views/Markets";
import Overview from "views/Overview";
import Rewards from "views/Rewards";
import LandingPage from "views/LandingPage";

const App = () => {
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    try {
      const profile = cookie.load(COOKIE_NAME);
      const payload = jwtDecode(profile.accessToken);
      if (payload.exp - 3600 * 0.1 > Date.now() / 1000) {
        profileAction.signIn(profile);
      }
    } catch {
    } finally {
      setIsReady(true);
    }
  }, []);

  // return (
  //     isReady && (
  //         <ReduxProvider store={store}>
  //             <QueryClientProvider client={queryClient}>
  //                 <Router>
  //                     <>Hello</>
  //                 </Router>
  //             </QueryClientProvider>
  //         </ReduxProvider>
  //     )
  // );

  return (
    isReady && (
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <Router>
            {/* <Sidebar />
                        <AppWrapper>
                            <AppImageBackgroundWrapper />
                            <Switch>
                                <Route path="/markets">
                                    <Markets />
                                </Route>
                                <Route path="/overview">
                                    <Overview />
                                </Route>
                                <Route path="/rewards">
                                    <Rewards />
                                </Route>
                            </Switch>
                        </AppWrapper> */}

            <Switch>
              {/* <Route path="/" exact>
                <LandingPage />
              </Route> */}
              <AppWrapper>
                <Sidebar />
                <Route path="/markets">
                  <Markets />
                </Route>
                <Route path="/overview">
                  <Overview />
                </Route>
                <Route path="/">
                  <Overview />
                </Route>
                <Route path="/rewards">
                  <Rewards />
                </Route>
              </AppWrapper>
            </Switch>
          </Router>
        </QueryClientProvider>
      </ReduxProvider>
    )
  );
};

export default App;
