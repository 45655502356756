import { createStore, combineReducers } from 'redux';
import profile from './profile';
import system from './system';

export const store = createStore(
  combineReducers({
    profile,
    system,
  }),
  {},
);
