import React from 'react';
import { LandingPageWrapper } from './LandingPageStyle';
import { Button } from '@material-ui/core';
import logo from '../../assets/images/Header.png';
import bgShape from '../../assets/images/BG_Shape_1.png';
import layer from '../../assets/images/Layer.png';

import shape_left from '../../assets/images/Shape_left.png';
import shape_right from '../../assets/images/Shape_right.png';

import avax from '../../assets/images/coin_icons/savax_AVAX.png';
import avalanche from '../../assets/images/coin_icons/savax_avalanche.png';
import busd from '../../assets/images/coin_icons/savax_BUSD.png';
import bitcoin from '../../assets/images/coin_icons/savax_Bitcoin.png';
import ethereum from '../../assets/images/coin_icons/savax_ETH.png';
import chainlink from '../../assets/images/coin_icons/savax_ChainLink.png';
import tether from '../../assets/images/coin_icons/savax_Tether.png';
import usdcoin from '../../assets/images/coin_icons/savax_USD.png';
import dai from '../../assets/images/coin_icons/savax_DAI.png';
import igs from '../../assets/images/coin_icons/savax_IGS.png';

function LandingPage(props) {
    return (
        <LandingPageWrapper>
            <div className="Home">
                <div className="App-header">
                    <div className="App-logo">
                        <img src={logo} alt="logo" className="App-logo-img" />
                    </div>
                    <div className="App-menu">
                        <ul className="App-menu-list">
                            <li className="App-menu-list-item">
                                <a href="">Earn</a>
                            </li>
                            <li className="App-menu-list-item">
                                <a href="">Staking</a>
                            </li>
                            <li className="App-menu-list-item">
                                <a href="">Community</a>
                            </li>
                            <li className="App-menu-list-item">
                                <a href="">Docs</a>
                            </li>
                        </ul>
                    </div>
                    <div className="App-btn">
                        <Button
                            variant="contained"
                            className="App-btn"
                            style={{
                                backgroundColor: '#FFEE31',
                                width: '6.875rem',
                                height: '2.313rem',
                                borderRadius: '0.75rem',
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                                textShadow: '0 0.125rem 0.25rem #A33D00',
                                textTransform: 'none',
                                padding: '0 1rem 0.5rem',
                                fontFamily: 'Bahnschrift',
                            }}
                        >
                            App
                        </Button>
                    </div>
                </div>

                <div className="Home-background">
                    <img
                        src={bgShape}
                        alt="Bac"
                        className="Home-background_shape"
                    />
                </div>

                <div className="Center-text">
                    <div className="Center-text_Supply-Liquidity">
                        <span>Supplied Liquidity</span>
                    </div>
                    <div className="Center-text_money">
                        <span>$100.000.000</span>
                    </div>
                    <div className="Center-text_Describe">
                        <span>Supply, borrow and earn.</span>
                        <span>
                            All on IGNIS's suite of yield-generating DeFi
                            Protocols.
                        </span>
                    </div>
                </div>

                <div className="Power-text">
                    <span>Powered by</span>
                    <img src={layer} alt="" className="layer-img" />
                    <span>Flare</span>
                </div>
            </div>

            {/* *************************************** */}
            <div className="Services">
                <div className="Column-left">
                    <div className="Shape-left">
                        <img src={shape_left} alt="" className="" />
                    </div>
                    <div className="Main Main_left">
                        <div className="Content">
                            <div className="Content_left">
                                <div className="Content_title">
                                    <span>Algorithmic</span>
                                    <span>Liquidity</span>
                                    <span>Market</span>
                                </div>
                                <div className="Content_description">
                                    <span>
                                        Effortlessly supply, borrow and earn
                                        interest on your digital assets.
                                    </span>
                                </div>
                            </div>
                            <div className="Button-service ">
                                <div className="Btn-app BGr-0000">
                                    <span className="Btn-app-children">
                                        Launch App
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Column-right">
                    <div className="Shape-right">
                        <img src={shape_right} alt="" className="" />
                    </div>
                    <div className="Main Main_right">
                        <div className="Content">
                            <div className="Content_right">
                                <div className="Content_title">
                                    <span>Staking</span>
                                    <span style={{ opacity: 0 }}>Staking</span>
                                    <span style={{ opacity: 0 }}>Staking</span>
                                </div>
                                <div className="Content_description">
                                    <span>
                                        Stake IGS on Ignis`s Staking Protocol
                                        and freely utilize it within powerful
                                        Decentralized Finance applications.
                                    </span>
                                </div>
                            </div>
                            <div className="Button-service">
                                <div className="Btn-app">
                                    <span className="Btn-app-children">
                                        Launch App
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* *************************************** */}
            <div className="LendingStats">
                <div className="Title">
                    <span>$100.000.000</span>
                    <span>supplied on our Algorithmic Liquidity Market</span>
                </div>
                <div className="Assets">
                    <div className="Asset">
                        <div className="Assets-title">
                            <img src={avax} alt="Logo AVAX" />
                            <span>Staked AVAX</span>
                        </div>
                        <div className="Assets-stats">
                            <div>
                                <span>Supply APY</span>
                                <span>0.5</span>
                            </div>
                            <div>
                                <span>Total Supply</span>
                                <span>$100.000</span>
                            </div>
                        </div>
                    </div>
                    <div className="Asset">
                        <div className="Assets-title">
                            <img src={avalanche} alt="Logo Avalanche" />
                            <span>Avalanche</span>
                        </div>
                        <div className="Assets-stats">
                            <div>
                                <span>Supply APY</span>
                                <span>0.5</span>
                            </div>
                            <div>
                                <span>Total Supply</span>
                                <span>$100.000</span>
                            </div>
                        </div>
                    </div>
                    <div className="Asset">
                        <div className="Assets-title">
                            <img src={busd} alt="Logo BUSD" />
                            <span>BUSD</span>
                        </div>
                        <div className="Assets-stats">
                            <div>
                                <span>Supply APY</span>
                                <span>0.5</span>
                            </div>
                            <div>
                                <span>Total Supply</span>
                                <span>$100.000</span>
                            </div>
                        </div>
                    </div>
                    <div className="Asset">
                        <div className="Assets-title">
                            <img src={bitcoin} alt="Logo Bitcoin" />
                            <span>Bitcoin</span>
                        </div>
                        <div className="Assets-stats">
                            <div>
                                <span>Supply APY</span>
                                <span>0.5</span>
                            </div>
                            <div>
                                <span>Total Supply</span>
                                <span>$100.000</span>
                            </div>
                        </div>
                    </div>
                    <div className="Asset">
                        <div className="Assets-title">
                            <img src={ethereum} alt="Logo Ethereum" />
                            <span>Ethereum</span>
                        </div>
                        <div className="Assets-stats">
                            <div>
                                <span>Supply APY</span>
                                <span>0.5</span>
                            </div>
                            <div>
                                <span>Total Supply</span>
                                <span>$100.000</span>
                            </div>
                        </div>
                    </div>
                    <div className="Asset">
                        <div className="Assets-title">
                            <img src={chainlink} alt="Logo Chainlink" />
                            <span>Chainlink</span>
                        </div>
                        <div className="Assets-stats">
                            <div>
                                <span>Supply APY</span>
                                <span>0.5</span>
                            </div>
                            <div>
                                <span>Total Supply</span>
                                <span>$100.000</span>
                            </div>
                        </div>
                    </div>
                    <div className="Asset">
                        <div className="Assets-title">
                            <img src={tether} alt="Logo Tether" />
                            <span>Tether</span>
                        </div>
                        <div className="Assets-stats">
                            <div>
                                <span>Supply APY</span>
                                <span>0.5</span>
                            </div>
                            <div>
                                <span>Total Supply</span>
                                <span>$100.000</span>
                            </div>
                        </div>
                    </div>
                    <div className="Asset">
                        <div className="Assets-title">
                            <img src={usdcoin} alt="Logo USD Coin" />
                            <span>USD Coin</span>
                        </div>
                        <div className="Assets-stats">
                            <div>
                                <span>Supply APY</span>
                                <span>0.5</span>
                            </div>
                            <div>
                                <span>Total Supply</span>
                                <span>$100.000</span>
                            </div>
                        </div>
                    </div>
                    <div className="Asset">
                        <div className="Assets-title">
                            <img src={dai} alt="Logo DAI" />
                            <span>DAI</span>
                        </div>
                        <div className="Assets-stats">
                            <div>
                                <span>Supply APY</span>
                                <span>0.5</span>
                            </div>
                            <div>
                                <span>Total Supply</span>
                                <span>$100.000</span>
                            </div>
                        </div>
                    </div>
                    <div className="Asset">
                        <div className="Assets-title">
                            <img src={igs} alt="Logo IGS" />
                            <span>IGS</span>
                        </div>
                        <div className="Assets-stats">
                            <div>
                                <span>Supply APY</span>
                                <span>0.5</span>
                            </div>
                            <div>
                                <span>Total Supply</span>
                                <span>$100.000</span>
                            </div>
                        </div>
                    </div>
                    <div className="Asset">
                        <div className="Assets-title">
                            <img src={avax} alt="Logo AVAX" />
                            <span>Staked AVAX</span>
                        </div>
                        <div className="Assets-stats">
                            <div>
                                <span>Supply APY</span>
                                <span>0.5</span>
                            </div>
                            <div>
                                <span>Total Supply</span>
                                <span>$100.000</span>
                            </div>
                        </div>
                    </div>
                    <div className="Asset">
                        <div className="Assets-title">
                            <img src={avax} alt="Logo AVAX" />
                            <span>Staked AVAX</span>
                        </div>
                        <div className="Assets-stats">
                            <div>
                                <span>Supply APY</span>
                                <span>0.5</span>
                            </div>
                            <div>
                                <span>Total Supply</span>
                                <span>$100.000</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LandingPageWrapper>
    );
}

export default LandingPage;
