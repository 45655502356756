import React from 'react';
import { profileAction } from 'actions/profile';
import styled from 'styled-components';
import { Button } from 'antd';
import MetamaskImg from 'assets/images/metamask.png';
import useAuth from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 230px;
    .title {
        color: #0a1316;
        font-weight: 700;
        font-size: 50px;
        line-height: 32px;
        text-align: center;
        width: 100%;
    }
    .content {
        color: #0a1316;
        text-align: center;
        width: 100%;
        font-size: 30px;
    }
    button {
        height: 60px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        font-size: 30px;
        font-weight: bold;
        img {
            width: 40px;
            margin-right: 16px;
        }
    }
`;

const Login = () => {
    const history = useHistory();
    const { isAuth, isAdmin } = useAuth();
    const [isSubmiting, setIsSubmiting] = React.useState(false);

    const login = React.useCallback(async () => {
        setIsSubmiting(true);
        try {
            await profileAction.connect();
        } catch (error) {
            console.log({ error });
        } finally {
            setIsSubmiting(false);
        }
    }, []);

    React.useEffect(() => {
        if (isAuth) {
            if (isAdmin) {
                history.push('/admin/dashboard');
            } else {
                history.push('/dashboard');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth, isAdmin]);

    return (
        <Wrapper>
            <div className="title">Connect Wallet</div>
            <div className="content">Please connect wallet to continue</div>
            <Button
                type="primary"
                onClick={() => login()}
                loading={isSubmiting}
            >
                <img src={MetamaskImg} />
                Connect Metamask
            </Button>
        </Wrapper>
    );
};

export default Login;
