import React from 'react';
import { CardLayoutWrapper, CardWrapper } from 'styled-components/Card';

function Rewards(props) {
    return (
        <CardLayoutWrapper>
            <CardWrapper>Rewards</CardWrapper>
        </CardLayoutWrapper>
    );
}

export default Rewards;
