import styled from 'styled-components';

export const OverviewWrapper = styled.div`
    h1 {
        font-family: 'Bahnschrift';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
    }

    .banner-group {
        display: flex;
        justify-content: space-between;
        gap: 18px;
        margin-bottom: 2.5rem;
    }

    .assets-wrapper {
        display: flex;
        gap: 1rem;
        text-transform: uppercase;
        font-size: 0.875rem;
        .asset-item {
            width: 50%;
            h3 {
                font-weight: 300;
                margin-top: 0;
                margin-bottom: 8px;
            }
            .asset-box {
                display: flex;
                justify-content: center;
                background-image: linear-gradient(to bottom, #f63804, #ffee31);
                padding: 1px;
                border-radius: 0.375rem;

                .boxed-child {
                    width: 100%;
                    height: 100%;
                    padding: 0.75rem;
                    background-color: #250000;
                    border-radius: inherit;
                    text-align: center;
                }
            }
        }
    }

    .overview-table {
        width: 100%;
        border-spacing: 0 20px;

        .data-tr {
            background-color: #19000e;
            td {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                vertical-align: top;
                div {
                    white-space: nowrap;
                    text-align: right;
                }
            }

            td:first-child {
                padding-left: 1rem;
                border-radius: 0.375rem 0 0 0.375rem;
                div {
                    text-align: left;
                }
            }

            td:last-child {
                padding-right: 1rem;
                border-radius: 0 0.375rem 0.375rem 0;
            }
        }

        .th1 {
            text-align: center;
            font-weight: 300;
            div {
                margin-bottom: 12px;
            }
        }
        .th2 {
            text-align: right;
            text-transform: uppercase;
            font-size: 0.75rem;
            font-weight: 300;
        }
        .th2:first-child {
            text-align: left;
        }
        th {
            padding: 0 0.5rem;
        }
        td {
            text-align: left;
            padding: 0 0.5rem;
        }
    }
`;
