export const ActionType = {
  SYSTEM_SAVE: 'SYSTEM_SAVE',
};

const system = (state = { config: {}, payments: [], collections: [] }, { type, data }) => {
  switch (type) {
    case ActionType.SYSTEM_SAVE:
      return { ...state, ...data };
    default:
      return state;
  }
};

export default system;
