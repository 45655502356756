import styled from 'styled-components';

export const SidebarWrapper = styled.div`
    background-color: #19000e;
    transition: none 0s ease 0s;
    z-index: 49;
    position: fixed;
    height: 100%;
    overflow-y: auto;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 255px;

    a {
        display: block;
    }

    .sidebar-contain {
        padding: 1.875rem;

        .header-logo {
            margin: 8px 0 16px 0;
        }
    }
`;

export const ItemWrapper = styled.div`
    font-style: normal;
    font-weight: 300;
    font-size: 14px;

    // margin: 20px 0;
    // border-radius: 0.1875rem;
    // height: 2.25rem;
    // cursor: pointer;
    // text-transform: uppercase;
    // display: flex;
    // justify-content: ${(props) => (props.info ? 'space-between' : 'center')};
    // padding: ${(props) => (props.info ? '0 10px' : null)};
    // align-items: center;
    // background-color: #481a1a;

    .info-wrapper,
    span,
    a {
        margin: 20px 0;
        border-radius: 0.1875rem;
        height: 2.25rem;
        cursor: pointer;
        text-transform: uppercase;
        display: flex;
        justify-content: ${(props) =>
            props.info ? 'space-between' : 'center'};
        padding: ${(props) => (props.info ? '0 10px' : null)};
        align-items: center;
        background-color: #481a1a;

        // line-height: 1;
        color: #fff;
        text-decoration: none;
    }
    .active {
        background-color: #8b3f3f;
    }
    .icon {
        color: gray;
    }
    .icon-group {
        display: flex;
        gap: 4px;
        align-items: center;
    }

    margin-bottom: ${(props) => (props.br ? '56px' : '20px')};
`;
