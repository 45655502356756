import styled from 'styled-components';

export const BannerWrapper = styled.div`
    flex: 1;
    background-color: #3a1313;
    padding: 1.25rem;
    border-radius: 0.375rem;

    .title {
        font-size: 0.9375rem;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
    }

    .value {
        font-size: 1.25rem;
    }

    img {
        padding-left: 8px;
        width: 12px;
        height: 12px;
    }

    .borrow-wrapper {
        font-size: 0.75rem;
        display: inline-flex;
        gap: 0.5rem;
        justify-content: space-between;
        align-items: flex-start;

        .health-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.25rem;
            .health-icon {
                border-radius: 50%;
                background-color: rgb(46, 204, 64);
                width: 12px;
                height: 12px;
                display: inline-block;
            }
        }
        .remaining-wrapper {
            display: flex;
            gap: 0.25rem;
            align-items: center;
        }
    }
`;
