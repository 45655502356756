import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { SidebarWrapper, ItemWrapper, InfoItemWrapper } from './SidebarStyle';
import HeaderLogo from '../../assets/images/Header.png';
import IgsIcon from '../../assets/images/igs_icon.png';
import Igs from '../../assets/images/IGS.png';

const LinkItem = ({ content, path }) => {
    return (
        <ItemWrapper>
            <NavLink to={path}>{content}</NavLink>
        </ItemWrapper>
    );
};

const InfoItem = ({ value }) => {
    return (
        <ItemWrapper info={true}>
            <div className="info-wrapper">
                <span className="icon-group">
                    <img src={IgsIcon} height={22} />
                    <img src={Igs} height={10} />
                </span>
                <span>{value}</span>
            </div>
        </ItemWrapper>
    );
};

const AccountItem = ({ content }) => {
    return (
        <ItemWrapper>
            <span>{content}</span>
        </ItemWrapper>
    );
};

function Sidebar(props) {
    return (
        <SidebarWrapper>
            <div className="sidebar-contain">
                <img className="header-logo" src={HeaderLogo} height={36} />
                <InfoItem value={200} />
                <AccountItem content={'0x464F...83e5'} />

                <hr style={{ border: 'none' }}></hr>

                <LinkItem content={'Markets'} path="/markets" />
                <LinkItem content={'Overview'} path="/overview" />
                <LinkItem content={'Rewards'} path="/rewards" />

                <hr style={{ border: 'none' }}></hr>

                <AccountItem content={'English'} />
            </div>
        </SidebarWrapper>
    );
}

export default Sidebar;
