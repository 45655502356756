import { store } from 'reducers';
import { ActionType } from 'reducers/profile';
import { COOKIE_NAME } from 'utils/constants';
import { web3 } from 'contracts/contract';
import { accountService } from 'services';
import cookie from 'react-cookies';
import { notification } from 'antd';

const connect = async (callback) => {
  const { isLoggedIn, address } = store.getState().profile;
  if (isLoggedIn) {
    if (typeof callback === 'function') callback(address);
  } else {
    try {
      let account;
      try {
        [account] = await web3.eth.requestAccounts();
      } catch {
        [account] = await web3.eth.getAccounts();
      }
      if (account) {
        const { address, nonce } = await accountService.nonce({ address: account });
        const message = `${nonce}`;
        const signature = await web3.eth.personal.sign(message, address);
        const login = await accountService.login({ address, signature });
        signIn({ ...login, user: address, isLoggedIn: true });
        const profile = await accountService.getProfile();
        signIn({ ...profile });
        cookie.save(COOKIE_NAME, { ...login, user: address, isLoggedIn: true, ...profile });
        if (typeof callback === 'function') callback(address);
      }
    } catch (error) {
      signOut();
      if (error?.message) {
        notification.error({ key: 'metamask', message: error?.message });
      }
    }
  }
};

const updateProfile = async (newProfile) => {
  const { profile } = store.getState().profile;
  store.dispatch({
    type: ActionType.USER_FETCH_PROFILE,
    data: { ...profile, ...newProfile },
  });
  cookie.save(COOKIE_NAME, { ...profile, ...newProfile });
};

const signIn = (data) => {
  store.dispatch({
    type: ActionType.USER_FETCH_PROFILE,
    data,
  });
};

const signOut = () => {
  cookie.remove(COOKIE_NAME);
  store.dispatch({
    type: ActionType.USER_LOGOUT,
  });
};

export const profileAction = {
  connect,
  signOut,
  signIn,
  updateProfile,
};
