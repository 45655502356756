import InfoBox from 'components/InfoBox';
import React, { useState } from 'react';
import { CardLayoutWrapper, CardWrapper } from 'styled-components/Card';
import { MarketWrapper } from './MarketStyle';
import { Slider } from '@material-ui/core';
import warningIcon from '../../assets/images/WarningIcon.png';
import infoIcon from '../../assets/images/InfoIcon.png';

const marks = [
    {
        value: 0,
        label: '0 %',
    },
    {
        value: 25,
        label: '25 %',
    },
    {
        value: 50,
        label: '50 %',
    },
    {
        value: 75,
        label: '75 %',
    },
    {
        value: 100,
        label: '100 %',
    },
];

const DATA = {
    assetInput: {
        value: {
            supply: 'Available: 0.0000 IGS ($0.00)',
            borrow: 'Borrow Limit: 0.0000 IGS ($0.00)',
        },
        unit: { supply: 'MAX', borrow: 'Health After Borrowing: 100.00' },
    },
    buttonLabel: { supply: 'Deposits', borrow: 'Borrow' },
    limitLabel: { supply: 'Supplied', borrow: 'Borrowed' },
    noAssets: { supply: 'No supplied assets', borrow: 'No borrowed assets' },
};

const renderSupplyTable = () => {
    return (
        <div className="info-wrapper">
            <div className="info-item">
                <div className="info-row">
                    <span>Deposit Rates</span>
                    <span></span>
                </div>
                <div className="info-row">
                    <span>Supply APY</span>
                    <span>4.97%</span>
                </div>
                <div className="info-row">
                    <span>Distribution APY</span>
                    <span>2.66%</span>
                </div>
            </div>
            <div className="info-item">
                <div className="info-row">&nbsp;</div>
                <div className="info-row">
                    <span>Supply APY</span>
                    <span>4.97%</span>
                </div>
                <div className="info-row">
                    <span>Distribution APY</span>
                    <span>2.66%</span>
                </div>
            </div>
        </div>
    );
};

const renderBorrowTable = () => {
    return (
        <div className="info-wrapper">
            <div className="info-item">
                <div className="info-row">
                    <span>Borrow Rates</span>
                    <span></span>
                </div>
                <div className="info-row">
                    <span>Borrow APY</span>
                    <span>5.58%</span>
                </div>
                <div className="info-row">
                    <span>Distribution APY</span>
                    <span>2.66%</span>
                </div>
            </div>

            <div
                className="info-item"
                style={{ maxWidth: '25px', paddingRight: '0' }}
            >
                <div className="info-row">&nbsp;</div>

                <div className="info-row">
                    <img src={infoIcon} />
                </div>
                <div className="info-row">
                    <img src={infoIcon} />
                </div>
            </div>

            <div className="info-item">
                <div className="info-row">
                    <span>Borrow Limits</span>
                    <span></span>
                </div>
                <div className="info-row">
                    <span>IGS</span>
                    <span>0.0000</span>
                </div>
                <div className="info-row">
                    <span>USD</span>
                    <span>0.00</span>
                </div>
                <div className="info-row">
                    <span>Limited Used</span>
                    <span>0.000%</span>
                </div>
                <div className="info-row">
                    <span>Pool Liquidity</span>
                    <span>723,178.88</span>
                </div>
                <div className="info-row">
                    <span>Market Borrow Cap</span>
                    <span>723,178.88</span>
                </div>
            </div>

            <div
                className="info-item"
                style={{ maxWidth: '25px', paddingRight: '0' }}
            >
                <div className="info-row">&nbsp;</div>
                <div className="info-row">
                    <img src={infoIcon} />
                </div>
            </div>
        </div>
    );
};

function Markets(props) {
    const [option, setOption] = useState('supply');

    return (
        <CardLayoutWrapper>
            <CardWrapper>
                <MarketWrapper>
                    <div className="netApy-wrapper">
                        <div className="netApy-content">
                            <span className="netApy-title">Net Apy</span>
                            {option === 'borrow' && <img src={infoIcon} />}
                            <span className="netApy-value">100 %</span>
                        </div>
                    </div>
                    <div className="buttons-wrapper">
                        <div
                            className={`button supply-button ${
                                option === 'supply' ? 'selected' : ''
                            }`}
                            onClick={() => setOption('supply')}
                        >
                            Supply
                        </div>
                        <div className="center-line">&nbsp;</div>
                        <div
                            className={`button borrow-button ${
                                option === 'borrow' ? 'selected' : ''
                            }`}
                            onClick={() => setOption('borrow')}
                        >
                            Borrow
                        </div>
                    </div>
                    <div className="content-wrapper">
                        {option === 'borrow' && (
                            <div className="warning">
                                <img src={warningIcon} />
                                <div className="warning-text">
                                    <div>No collateralized assets</div>
                                    <div>
                                        Please enable your supplied assets as
                                        collateral to borrow.
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="assetInput-form">
                            <div className="value">
                                <InfoBox
                                    title={DATA['assetInput']['value'][option]}
                                    content="0.00"
                                    boxStyle={{
                                        textAlign: 'left',
                                        backgroundColor: '#481a1a',
                                    }}
                                />
                            </div>
                            <div className="unit">
                                <InfoBox
                                    title={DATA['assetInput']['unit'][option]}
                                    content="IGS"
                                    boxStyle={{
                                        textAlign: 'left',
                                        backgroundColor: '#481a1a',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="exchange">
                            <h4>-$0.00</h4>
                            <h4>1 IGS = $60.55</h4>
                        </div>

                        <div className="slider-wrapper">
                            <Slider defaultValue={50} step={5} marks={marks} />
                        </div>

                        {option === 'supply'
                            ? renderSupplyTable()
                            : renderBorrowTable()}

                        <div className="button-wrapper">
                            <button>{DATA['buttonLabel'][option]}</button>
                        </div>
                    </div>

                    <div className="limit-used-wrapper">
                        <div>{DATA['limitLabel'][option]}</div>
                        <div>Limit used: 100%</div>
                    </div>

                    <div className="assets-wrapper">
                        {DATA['noAssets'][option]}
                    </div>
                </MarketWrapper>
            </CardWrapper>
        </CardLayoutWrapper>
    );
}

export default Markets;
