import Web3 from "web3";
import { BSC_CONFIG } from "utils/constants";

import erc20Abi from "./abis/ERC20.json";
import erc721Abi from "./abis/ERC721.json";
import erc1155Abi from "./abis/ERC1155.json";
import api from "./abis/API.json";

export let web3 = window.ethereum
  ? new Web3(window.ethereum)
  : new Web3(new Web3.providers.HttpProvider(BSC_CONFIG.rpcUrls[0]));
export const updateWeb3 = (newWeb3) => {
  web3 = newWeb3;
};

export const erc20Contract = (address) =>
  new web3.eth.Contract(erc20Abi, address);
export const erc721Contract = (address) =>
  new web3.eth.Contract(erc721Abi, address);
export const erc1155Contract = (address) =>
  new web3.eth.Contract(erc1155Abi, address);
export const contract = (address) => new web3.eth.Contract(api, address);
