import React from "react";
import styled from "styled-components";
import { profileAction } from "actions/profile";
import { Button } from "antd";
import LogoImg from "assets/images/logo.png";
import useAuth from "hooks/useAuth";
import { useHistory, useLocation } from "react-router-dom";

const Wrapper = styled.div`
  margin: 0 auto;
  position: relative;
  .header {
    height: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: black;
    padding: 0 40px;
    .logo {
      cursor: pointer;
      font-weight: 700;
    }
    img {
      margin-right: 12px;
    }
    .ant-btn-primary {
      background: #f89603;
      border-color: #f89603;
      border-radius: 8px;
      margin-right: 100px;
    }
  }
  .containner {
    padding: 40px;
    max-width: 1920px;
    margin: 0 auto;
  }
`;

const Frame = ({ children }) => {
  const { isAuth, isAdmin, address } = useAuth();
  const history = useHistory();
  const [isLogging, setIsLogging] = React.useState(false);
  const currentRoute = useLocation();
  const login = React.useCallback(async () => {
    setIsLogging(true);
    try {
      await profileAction.connect();
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLogging(false);
    }
  }, []);

  const logout = React.useCallback(async () => {
    setIsLogging(true);
    try {
      await profileAction.signOut();
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLogging(false);
    }
  }, []);

  React.useEffect(() => {
    if (!isAuth) {
      history.push("/");
    }
  }, [history, isAdmin, isAuth]);

  return (
    <Wrapper>
      <div className="header">
        <div className="logo" onClick={() => history.push("/")}>
          <img src={LogoImg} height={60} />
        </div>

        <div className="action">
          {isAuth ? (
            <div className="flex-center">
              <span style={{ marginRight: 16 }}>{address}</span>
              <Button
                type="primary"
                onClick={() => logout()}
                loading={isLogging}
                style={{
                  width: 200,
                  height: 40,
                }}
              >
                Disconnect
              </Button>
            </div>
          ) : (
            <Button
              type="primary"
              onClick={() => login()}
              loading={isLogging}
              style={{
                width: 200,
                height: 40,
              }}
            >
              Connect
            </Button>
          )}
        </div>
      </div>
      <div className="containner">{children}</div>
    </Wrapper>
  );
};

export default Frame;
