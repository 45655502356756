export const ActionType = {
  USER_LOGIN: 'USER_LOGIN',
  USER_FETCH_PROFILE: 'USER_FETCH_PROFILE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const profile = (state = {}, { type, data }) => {
  switch (type) {
    case ActionType.USER_LOGIN:
      return { ...state, ...data, isLoggedIn: true };
    case ActionType.USER_FETCH_PROFILE:
      return { ...state, ...data };
    case ActionType.USER_LOGOUT:
      return { ...state, isLoggedIn: false, user: null, role: null };
    default:
      return state;
  }
};

export default profile;
