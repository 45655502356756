import React from 'react';
import { CardLayoutWrapper, CardWrapper } from 'styled-components/Card';
import Banner from './Banner';
import { OverviewWrapper } from './OverviewStyle';
import { tableData } from 'mock-data/data';
import InfoBox from 'components/InfoBox';

function Overview(props) {
    return (
        <CardLayoutWrapper>
            <CardWrapper>
                <OverviewWrapper>
                    <h1>Overview</h1>
                    <div className="banner-group">
                        <Banner title="Total Supply" value={125700.12} />
                        <Banner title="Total Borrow" value={34690.901} />
                        <Banner
                            title="Your Borrow Limit"
                            value={0.12}
                            percentValue={true}
                        />
                    </div>
                    <div className="assets-wrapper">
                        <div className="asset-item">
                            <InfoBox
                                title="Supplied"
                                content="No supplied asset"
                            />
                        </div>
                        <div className="asset-item">
                            <InfoBox
                                title="Borrowed"
                                content="No borrowed assets"
                            />
                        </div>
                    </div>

                    <div style={{ overflowX: 'auto' }}>
                        <table className="overview-table">
                            <tr>
                                <th className="th1"></th>
                                <th className="th1" colSpan={3}>
                                    <div>Supply</div>
                                </th>
                                <th className="th1" colSpan={2}>
                                    <div>Borrow</div>
                                </th>
                            </tr>
                            <tr>
                                <th className="th2">Asset</th>
                                <th className="th2">Total APY</th>
                                <th className="th2">Total Supply</th>
                                <th className="th2">Total APY</th>
                                <th className="th2">Total Borrow</th>
                                <th className="th2">Total Liquidity</th>
                            </tr>

                            {tableData.map((row) => (
                                <tr className="data-tr">
                                    <td>
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: '16px',
                                            }}
                                        >
                                            <img
                                                src={row.asset.icon}
                                                style={{
                                                    width: '2rem',
                                                    height: '2rem',
                                                }}
                                            />
                                            <div>
                                                <div>
                                                    {row.asset.firstValue}
                                                </div>
                                                <div>
                                                    {row.asset.secondValue}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {row.supplyTotalApy.firstValue}
                                        </div>
                                        <div>
                                            {row.supplyTotalApy.secondValue}
                                        </div>
                                    </td>
                                    <td>
                                        <div>{row.totalSupply.firstValue}</div>
                                        <div>{row.totalSupply.secondValue}</div>
                                    </td>
                                    <td>
                                        <div>
                                            {row.borrowTotalApy.firstValue}
                                        </div>
                                        <div>
                                            {row.borrowTotalApy.secondValue}
                                        </div>
                                    </td>
                                    <td>
                                        <div>{row.totalBorrow.firstValue}</div>
                                        <div>{row.totalBorrow.secondValue}</div>
                                    </td>
                                    <td>
                                        <div>
                                            {row.totalLiquidity.firstValue}
                                        </div>
                                        <div>
                                            {row.totalLiquidity.secondValue}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </OverviewWrapper>
            </CardWrapper>
        </CardLayoutWrapper>
    );
}

export default Overview;
