import styled from 'styled-components';

export const MarketWrapper = styled.div`
    .netApy-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;

        .netApy-content {
            display: flex;
            align-items: flex-start;
            gap: 12px;
            .netApy-title {
                text-transform: uppercase;
            }
            .netApy-value {
                font-size: 24px;
            }
        }
    }

    .buttons-wrapper {
        display: flex;
        align-items: flex-end;
        .button {
            flex: 1;
            text-align: center;
            background-color: yellow;
            padding: 12px 0;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 600;
            background-color: #3a1313;
            cursor: pointer;

            border-bottom: solid 2px #390101;
            border-top-left-radius: 0.375rem;
            border-top-right-radius: 0.375rem;
        }
        .center-line {
            width: 12px;
            border-bottom: solid 2px #390101;
        }

        .selected {
            background-color: #481a1a;

            border: solid 2px #390101;
            border-bottom: none;
        }
    }

    .content-wrapper {
        background-color: #481a1a;
        padding: 26px 28px;
        margin-bottom: 26px;
        border: solid 2px #390101;
        border-top: none;
        .warning {
            font-weight: 700;
            background-color: #9a2022;
            color: #000;
            border-radius: 8px;
            padding: 12px 18px;
            margin-bottom: 26px;

            display: flex;
            align-items: center;
            gap: 12px;

            .warning-text {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
        }

        .assetInput-form {
            display: flex;
            gap: 12px;
            .value {
                width: 65%;
            }
            .unit {
                width: 35%;
            }
        }

        .exchange {
            display: flex;
            justify-content: space-between;
            h4 {
                font-weight: 300;
            }
        }

        .slider-wrapper {
            margin-bottom: 12px;
            .MuiSlider-root {
                color: #fff;
                .MuiSlider-rail {
                    height: 4px;
                }
                .MuiSlider-track {
                    height: 4px;
                }
                .MuiSlider-mark {
                    width: 4px;
                    height: 4px;
                }
                .MuiSlider-markLabel {
                    color: #fff;
                }
            }
        }

        .info-wrapper {
            background-color: #19000e;
            border-radius: 8px;
            padding: 0.75rem;
            display: flex;
            gap: 0.75rem;
            margin-bottom: 24px;
            .info-item {
                flex: 1;
                padding-right: 12px;
                .info-row {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 12px;
                }
                .info-row:last-child {
                    padding-bottom: 0;
                }
            }
        }

        .button-wrapper {
            text-align: center;
            button {
                font-size: 1.375rem;
                text-transform: uppercase;
                padding: 12px;
                border-radius: 18px;
                color: #fff;
                background-color: #331425;
                border: none;
            }
        }
    }

    .limit-used-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
        div {
            width: 50%;
        }
    }

    .assets-wrapper {
        background-color: #19000e;
        border-radius: 8px;
        padding: 0.75rem;
        text-align: center;
    }
`;
